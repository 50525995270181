import React from "react";
import "./Item.css";
import {
  Button,
  Spinner,
  Avatar,
  Input,
  Divider,
  Textarea,
  CompoundButton,
} from "@fluentui/react-components";
import {
  ArrowLeftFilled,
  Send28Filled,
  CommentFilled,
} from "@fluentui/react-icons";
import { commentQuery, createComment } from "../../query";
import { useEffect } from "react";
import { refreshToken } from "../../authUtil";

export function ItemComments({ post, msID, setItem, screenSize }) {
  const [replies, setReplies] = React.useState([]);
  const [showReplies, setShowReplies] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  console.log(post);

  const getReplies = async () => {
    const user = await refreshToken({ user: msID });
    const postQS = commentQuery(post.id);
    setLoading(true);
    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(postQS),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setLoading(false);
        setReplies(data.data.listComments.items);
      });
  };

  const postReply = async () => {
    const user = await refreshToken({ user: msID });
    const postC = createComment(post.id, "<p>" + comment + "</p>");

    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(postC),
    }).then(function (response) {
      console.log(response);
      setComment("");
      getReplies();
    });
  };

  return (
    <div>
      <div className="post">
        <div style={{ display: "inline-flex" }}>
          <Avatar
            name={post.user?.firstName + " " + post.user?.lastName}
            size={40}
            image={{ src: post.user?.avatar?.url }}
          />
          <div style={{ marginLeft: 10 }}>
            <p>{post?.user?.username}</p>
            <p>{post.createdAt.substr(0, 10)}</p>
          </div>
        </div>
        <div style={{ padding: "10px 0 0 0" }}>
          {post?.message && post?.message.replace(/<.*?>/g, "")}
        </div>
        <Divider style={{ padding: "10px 0" }} />
        <Button
          onClick={() => {
            setShowReplies(!showReplies);
            getReplies();
          }}
        >
          {post.totalComments} Comments
        </Button>
      </div>

      {showReplies == true ? (
        <div className="replies">
          <div className="postComment">
            <Textarea
              onChange={(val, data) => {
                console.log(val);
                console.log(data);
                setComment(data.value);
              }}
              value={comment}
              placeholder="Write comment here..."
              style={{ flex: 6 }}
            />
            <CompoundButton
              onClick={() => postReply()}
              style={{ flex: 1 }}
              icon={<Send28Filled />}
              appearance={"subtle"}
              size={"small"}
            >
              Comment
            </CompoundButton>
          </div>
          <div>
            {replies.length > 0 &&
              replies.map((reply, i) => (
                <div className="reply">
                  <div style={{ display: "inline-flex" }}>
                    <Avatar
                      name={reply.user?.firstName + " " + reply.user?.lastName}
                      size={40}
                      image={{ src: reply.user?.avatar?.url }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <p>{reply?.user?.username}</p>
                      <p>{reply?.createdAt.substr(0, 10)}</p>
                    </div>
                  </div>
                  <Divider style={{ padding: "10px 0 0 0" }} />
                  <div style={{ padding: "10px 0 0 0" }}>
                    {reply.message && reply?.message?.replace(/<.*?>/g, "")}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
