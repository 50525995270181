import React from "react";
import "./Issues.css";
import { issueQuery, issueSearchQuery } from "../../query";
import {
  Button,
  Spinner,
  Avatar,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "@fluentui/react-components";
import { useEffect } from "react";
import { refreshToken } from "../../authUtil";
import { Item } from "./Item";

import { logEvent } from "firebase/analytics";

export function Issues({ msID, query, analytics }) {
  const [listItems, setListItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    fetchQueryData();
  }, [query]);

  useEffect(() => {
    fetchQueryData();
  }, []);

  useEffect(() => {
    //window.addEventListener("scroll", handleScroll);
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // const handleScroll = () => {
  //   let debounce;
  //   if (debounce) clearTimeout(debounce);

  //   //start a new delay
  //   debounce = setTimeout(function () {
  //     //remove reference so another delay can start
  //     debounce = null;
  //     //perform whatever logic you normally would do
  //     let div = document.getElementById("root");
  //     let innerHeight = div.clientHeight;
  //     // console.log(window.scrollY);
  //     // console.log(window.innerHeight);
  //     // console.log(innerHeight);

  //     // console.log("computed", window.scrollY + window.innerHeight);
  //     // console.log("inner", innerHeight - 30);
  //     if (
  //       window.scrollY + window.innerHeight >= innerHeight - 30 &&
  //       listItems.length > 0
  //     ) {
  //       loading = true;
  //       console.log("end reached");
  //       fetchData();
  //     }
  //   }, 2000);
  // };

  const fetchQueryData = async () => {
    setLoading(true);
    const user = await refreshToken({ user: msID });
    const searchQuery = issueSearchQuery(query);
    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(searchQuery),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("data", data);
        setListItems(data.data.listIssues.items);
        setLoading(false);
      });
  };

  const fetchData = async () => {
    loading = true;
    //This will slow down the query
    const user = await refreshToken({ user: msID });
    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(issueSearchQuery(query)),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setListItems(data.data.listIssues.items);
        console.log(data);
        loading = false;
      });
  };

  return (
    <div className="deploy page">
      {selectedItem && // 👈 null and undefined check
      Object.keys(selectedItem).length === 0 &&
      Object.getPrototypeOf(selectedItem) === Object.prototype ? (
        <div>
          {matches && (
            <div style={{ minHeight: 200, overflowY: "scroll" }}>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Issue Name</TableHeaderCell>
                    {/* <TableHeaderCell>Assignee</TableHeaderCell> */}
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Priority</TableHeaderCell>
                    <TableHeaderCell>Start Date</TableHeaderCell>
                    <TableHeaderCell>End Date</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {listItems.length > 0 &&
                    listItems.map((item, i) => (
                      <TableRow
                        className="table"
                        style={{ backgroundColor: "#121212" }}
                        onClick={() => setSelectedItem(item)}
                      >
                        <TableCell>{item?.subject}</TableCell>
                        {/* <TableCell>
                          <div style={{ display: "inline-flex" }}>
                            <Avatar
                              name={item?.user?.nickname}
                              image={{ src: item?.user?.avatar?.url }}
                              size={20}
                            />
                            <div style={{ marginLeft: 5 }}>
                              {item?.user?.nickname}
                            </div>
                          </div>
                        </TableCell> */}
                        <TableCell>{item.status}</TableCell>
                        <TableHeaderCell>{item.priority}</TableHeaderCell>
                        <TableHeaderCell>
                          {item?.estimatedStartDate?.substr(0, 10)}
                        </TableHeaderCell>
                        <TableHeaderCell>
                          {item?.estimatedEndDate?.substr(0, 10)}
                        </TableHeaderCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {loading ? (
                <Spinner
                  style={{ margin: 20 }}
                  appearance="inverted"
                  label="Loading"
                />
              ) : (
                !loading &&
                listItems.length == 0 && (
                  <p style={{ margin: 20, textAlign: "center" }}>No data</p>
                )
              )}
            </div>
          )}
          {!matches && (
            <div>
              {listItems.length > 0 ? (
                listItems.map((item, i) => (
                  <div
                    key={i}
                    onClick={() => setSelectedItem(item)}
                    className="issueCard"
                    style={{ display: "flex" }}
                  >
                    <div style={{ color: "#fff" }}>
                      {item.type ? item.type + " · " : null}
                      {item.readableId
                        ? item?.__typename.toUpperCase() + "-" + item.readableId
                        : null}
                      {item?.priority ? " · " + item?.priority : null}
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "2",
                      }}
                    >
                      {item?.subject}
                    </div>
                    <div>{item?.description}</div>
                    <div
                      style={{
                        color: "#fff",
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.status ? item.status + " - " : null}
                      {/* {item?.user ? (
                        <Avatar
                          size={24}
                          name={item?.user?.nickname}
                          image={{ src: item?.user?.avatar?.url }}
                        />
                      ) : null} */}
                    </div>
                  </div>
                ))
              ) : (
                <Spinner appearance="inverted" label="Loading" />
              )}
            </div>
          )}
        </div>
      ) : (
        <Item
          screenSize={matches}
          type="issue"
          item={selectedItem}
          msID={msID}
          setItem={setSelectedItem}
        />
      )}
    </div>
  );
}
