import mapboxgl from "mapbox-gl";
import { useRef, useState, useEffect } from "react";

export function FieldDC({ setToken, msID }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);
  mapboxgl.accessToken =
    "pk.eyJ1IjoibWhhcnlzdCIsImEiOiJja2l0MHVnaDQyN2VuMnBwM2ZxNGNrang5In0.uhWHdo6wsITDysK8BoOZNw";

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <div style={{ display: "flex", height: 500, width: "100vw" }}>
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div
        ref={mapContainer}
        style={{ display: "flex", height: "100vh", width: "100vw" }}
        height={500}
        className="map-container"
      />
      <p>Map Box</p>
    </div>
  );
}
