import React from "react";
import {
  Spinner,
  Persona,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "@fluentui/react-components";
import { wellQuery } from "../../query";
import { useEffect } from "react";
import "./Wells.css";
import { refreshToken } from "../../authUtil";
import { Item } from "./Item";

export function Wells({ msID, query }) {
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const [listItems, setListItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const fetchData = async (query) => {
    //This will slow down the query
    const user = await refreshToken({ user: msID });
    const searchQuery = wellQuery(query);
    setLoading(true);
    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(searchQuery),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setLoading(false);
        setListItems(data.data.listWells.items);
      });
  };

  useEffect(() => {
    fetchData(query);
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    fetchData(query);
  }, [query]);

  return (
    <div className="deploy page">
      {selectedItem && // 👈 null and undefined check
      Object.keys(selectedItem).length === 0 &&
      Object.getPrototypeOf(selectedItem) === Object.prototype ? (
        <div>
          {matches && (
            <div style={{ minHeight: 200, overflowY: "scroll" }}>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>Well Name</TableHeaderCell>
                    <TableHeaderCell>Formation</TableHeaderCell>
                    <TableHeaderCell>Latitude</TableHeaderCell>
                    <TableHeaderCell>Longitude</TableHeaderCell>
                    <TableHeaderCell>Health</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {listItems.length > 0
                    ? listItems.map((item, i) => (
                        <TableRow
                          className="table"
                          style={{ backgroundColor: "#121212" }}
                          onClick={() => setSelectedItem(item)}
                        >
                          <TableCell>WELL-{item?.readableId}</TableCell>
                          <TableCell>
                            {item?.subject ? item.subject : item.name}
                          </TableCell>
                          <TableCell>{item.formation}</TableCell>
                          <TableHeaderCell>
                            {item.location?.coordinates[0].toFixed(5)}
                          </TableHeaderCell>
                          <TableHeaderCell>
                            {item.location?.coordinates[1].toFixed(5)}
                          </TableHeaderCell>
                          <TableHeaderCell>
                            {(item.healthLevel * 100).toFixed(2)}%
                          </TableHeaderCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
              {loading ? (
                <Spinner
                  style={{ margin: 20 }}
                  appearance="inverted"
                  label="Loading"
                />
              ) : (
                !loading &&
                listItems.length == 0 && (
                  <p style={{ margin: 20, textAlign: "center" }}>No data</p>
                )
              )}
            </div>
          )}
          {!matches && (
            <div style={{ overflowY: "scroll", height: 500 }}>
              {/* <div>
          <Input
            contentBefore={<SearchFilled />}
            placeholder="Search Wells"
            id={beforeId}
          />
        </div> */}
              {listItems.length > 0 &&
                listItems.map((item, i) => (
                  <div
                    key={i}
                    onClick={() => setSelectedItem(item)}
                    className="issueCard"
                    style={{ display: "flex" }}
                  >
                    <div style={{ color: "#fff" }}>
                      {item.type ? item.type + " · " : null}
                      {item.readableId
                        ? item?.__typename.toUpperCase() + "-" + item.readableId
                        : null}
                      {item?.priority ? " · " + item?.priority : null}
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "2",
                      }}
                    >
                      {item?.subject ? item.subject : item.name}
                    </div>
                    <div>{item?.description}</div>
                    <div
                      style={{
                        color: "#fff",
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.status ? item.status + " - " : null}
                      {item?.user ? (
                        <Persona
                          imageUrl={
                            item?.user?.avatar?.url
                              ? item?.user?.avatar?.url
                              : null
                          }
                          text={item?.user?.nickname}
                          //size={PersonaSize.size24}
                          imageAlt={item?.user?.nickname}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      ) : (
        <Item
          screenSize={matches}
          type={"well"}
          item={selectedItem}
          msID={msID}
          setItem={setSelectedItem}
        />
      )}
    </div>
  );
}
