import React from "react";
import "./Item.css";
import {
  Button,
  Spinner,
  Avatar,
  Input,
  Label,
  Divider,
  Textarea,
  CompoundButton,
} from "@fluentui/react-components";
import { ArrowLeftFilled, Send28Filled } from "@fluentui/react-icons";
import { postQuery, createPost } from "../../query";
import { useEffect } from "react";
import { refreshToken } from "../../authUtil";
import { ItemComments } from "./ItemComments";

export function Item({
  item,
  msID,
  setItem,
  screenSize,
  type,
  message,
  teamsUrl,
  fromUser,
}) {
  const [postlist, setPostList] = React.useState([]);
  const [comment, setComment] = React.useState(message);
  const [user, setUser] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const getPosts = async () => {
    const user = await refreshToken({ user: msID });
    setUser(user);
    const postQS = postQuery(item.id);
    setLoading(true);
    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(postQS),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setLoading(false);
        setPostList(data.data.listPosts.items);
      });
  };

  useEffect(() => {
    getPosts();
  }, []);

  const postComment = async () => {
    const user = await refreshToken({ user: msID });
    const postC = createPost(
      item.id,
      "<p>" +
        comment +
        "</p> From - " +
        fromUser +
        " via Teams: </p> <a href=" +
        teamsUrl +
        "/>" +
        teamsUrl +
        "</a>"
    );

    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(postC),
    }).then(function (response) {
      console.log(response);
      setComment("");
      getPosts();
    });
  };

  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      <div className="header">
        <div
          style={{ display: "inline-flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => setItem({})}
              style={{ marginRight: 10 }}
              size="small"
              icon={<ArrowLeftFilled />}
            >
              Go back
            </Button>
            <Label size="large">
              {item.subject ? item.subject : item.name}
            </Label>
          </div>
          <div>
            <a
              href={
                "https://" +
                user.tenant +
                ".resnet.ai/" +
                (type == "issue"
                  ? "issues/issue/"
                  : type == "well"
                  ? "well-space/well/"
                  : type == "site"
                  ? "sites/site/"
                  : type == "battery"
                  ? "batteries/battery/"
                  : "insights/insight/") +
                item.id
              }
              target={"_blank"}
            >
              <Button style={{ marginRight: 10 }} size="small">
                Open in WellTender
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className="postComment">
        <Textarea
          onChange={(val, data) => {
            console.log(val);
            console.log(data);
            setComment(data.value);
          }}
          value={comment}
          placeholder="Write comment here..."
          style={{ flex: 6 }}
        />
        <CompoundButton
          onClick={() => postComment()}
          style={{ flex: 1 }}
          icon={<Send28Filled />}
          appearance={"subtle"}
          secondaryContent={screenSize ? "Post comment" : null}
          size={"small"}
        >
          Submit
        </CompoundButton>
      </div>
      <div className="posts">
        {postlist && postlist.length > 0 ? (
          <div>
            {postlist.map((post, i) => (
              <ItemComments post={post} msID={msID} />
            ))}
          </div>
        ) : (
          <div>
            {loading ? (
              <Spinner appearance="inverted" label="Loading commnets" />
            ) : (
              <p>No posts yet!</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
