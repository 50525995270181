import React, { useState } from "react";
import {
  TabList,
  Tab,
  makeStyles,
  Button,
  Input,
  Spinner,
} from "@fluentui/react-components";
import { SearchFilled, SignOutFilled } from "@fluentui/react-icons";
import "./Welcome.css";
import Login from "./Login";
import { Issues } from "./Issues";
import { Batteries } from "./Batteries";
import { Wells } from "./Wells";
import { Sites } from "./Sites";
import { FieldDC } from "./Field";
import { useEffect } from "react";
import { Insights } from "./Insights";
import { refreshToken } from "../../authUtil";
import { CreateIssue } from "../botWebView/CreateIssue";
import { ShareTo } from "../botWebView/ShareTo";

export function Welcome(props) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchQueryTimer, setSearchQueryTimer] = React.useState("");

  const { environment, msID, isAuthed, getContext } = {
    environment: window.location.hostname === "localhost" ? "local" : "azure",
    ...props,
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchQuery(searchQueryTimer);
      //Set amount of time between last keystroke and search
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQueryTimer]);

  const [selectedMenuItem, setSelectedMenuItem] = useState("issues");

  const logout = async () => {
    const user = await refreshToken({ user: msID });
    fetch("https://resnet-msteams-auth.glitch.me/removeUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }).then(() => {
      getContext();
    });
  };

  return (
    <div className="home" style={{ backgroundColor: "#121212" }}>
      {isAuthed == false ? (
        <div className="narrow">
          <Login msID={msID} getContext={getContext} />
        </div>
      ) : isAuthed == true && msID != null ? (
        <div>
          <div className={"nav"}>
            <TabList
              defaultSelectedValue={"issues"}
              onTabSelect={(val, data) => {
                console.log(val);
                console.log(data);
                setSelectedMenuItem(data.value);
                setSearchQuery("");
                setSearchQueryTimer("");
              }}
              underlined
              secondary
            >
              <Tab value="issues">Issues</Tab>
              <Tab value="wells">Wells</Tab>
              <Tab value="sites">Sites</Tab>
              <Tab value="batteries">Batteries</Tab>
              <Tab value="insights">Insights</Tab>
              {/* <Tab value="fdc">FDC</Tab>
              <Tab value="createIssue">Create Issue</Tab>
              <Tab value="shareTo">Share To..</Tab> */}
            </TabList>
            <div style={{ display: "inline-flex" }}>
              {selectedMenuItem != "insights" && (
                <Input
                  contentBefore={<SearchFilled />}
                  placeholder="Search..."
                  style={{ marginRight: 20 }}
                  value={searchQueryTimer}
                  onChange={(val, data) => setSearchQueryTimer(data.value)}
                />
              )}

              <Button
                onClick={() => logout()}
                size="small"
                icon={<SignOutFilled />}
              >
                Logout
              </Button>
            </div>
          </div>
          <div className="sections">
            {selectedMenuItem === "issues" && (
              <div>
                <Issues msID={msID} query={searchQuery} />
              </div>
            )}
            {selectedMenuItem === "wells" && (
              <div>
                <Wells msID={msID} query={searchQuery} />
              </div>
            )}
            {selectedMenuItem === "batteries" && (
              <div>
                <Batteries msID={msID} query={searchQuery} />
              </div>
            )}
            {selectedMenuItem === "sites" && (
              <div>
                <Sites msID={msID} query={searchQuery} />
              </div>
            )}
            {selectedMenuItem === "insights" && (
              <div>
                <Insights msID={msID} query={searchQuery} />
              </div>
            )}
            {selectedMenuItem === "fdc" && (
              <div>
                <FieldDC msID={msID} />
              </div>
            )}
            {selectedMenuItem === "createIssue" && (
              <div>
                <CreateIssue msID={msID} />
              </div>
            )}
            {selectedMenuItem === "shareTo" && (
              <div>
                <ShareTo msID={msID} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <Spinner appearance="inverted" />
      )}
    </div>
  );
}
const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "50px",
    rowGap: "20px",
  },
});
