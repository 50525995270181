import React from "react";
import "./ShareTo.css";
import {
  Button,
  Spinner,
  Persona,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
  Combobox,
  Option,
  Input,
} from "@fluentui/react-components";
import { SearchFilled, SignOutFilled } from "@fluentui/react-icons";
import {
  issueQuery,
  siteQuery,
  issueSearchQuery,
  wellQuery,
  batteryQuery,
  insightQuery,
} from "../../query";
import { useEffect } from "react";
import { refreshToken } from "../../authUtil";
import { Item } from "../tabViews/Item";
import Login from "../tabViews/Login";

export function ShareTo({ msID, isAuthed, getContext }) {
  const objectTypes = ["Issue", "Battery", "Site", "Well", "Insight"];
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("Issue");
  const [query, setQuery] = React.useState("");
  const [listItems, setListItems] = React.useState([]);
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const hash = window.location.hash;
  let params = hash.split("&");
  let data = [];

  params.forEach(function (d) {
    let index = d.indexOf("=");
    let keyLength = index > -1 ? index : d.length;
    let key = d.substr(0, keyLength);
    let value = index > -1 ? d.substr(index + 1) : "";
    data.push({ key: key, value: value });
  });

  console.log(data);
  const decoded = decodeURI(data[0].value);
  const message = decoded.replace(/<.*?>/g, "");
  console.log(data);
  const teamsUrl = data[1].value;
  const fromUser = decodeURI(data[2].value);

  const [selectedItem, setSelectedItem] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const fetchData = async (query) => {
    //This will slow down the query
    const user = await refreshToken({ user: msID });

    const searchQuery =
      selectedMenuItem == "Issue"
        ? issueSearchQuery(query)
        : selectedMenuItem == "Battery"
        ? batteryQuery(query)
        : selectedMenuItem == "Well"
        ? wellQuery(query)
        : selectedMenuItem == "Site"
        ? siteQuery(query)
        : insightQuery(query);
    setLoading(true);
    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";
    fetch(baseUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      body: JSON.stringify(searchQuery),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setLoading(false);
        selectedMenuItem == "Issue"
          ? setListItems(data.data.listIssues.items)
          : selectedMenuItem == "Battery"
          ? setListItems(data.data.listBatteries.items)
          : selectedMenuItem == "Well"
          ? setListItems(data.data.listWells.items)
          : selectedMenuItem == "Site"
          ? setListItems(data.data.listSites.items)
          : setListItems(data.data.listInsights.items);
      });
  };

  useEffect(() => {
    fetchData(query);
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    setListItems([]);
    fetchData(query);
  }, [query]);

  useEffect(() => {
    setListItems([]);
    fetchData(query);
  }, [selectedMenuItem]);

  return (
    <div className="home" style={{ backgroundColor: "#121212" }}>
      {isAuthed == false ? (
        <div className="narrow">
          <Login msID={msID} getContext={getContext} />
        </div>
      ) : (
        <div className="deploy page">
          {selectedItem && // 👈 null and undefined check
          Object.keys(selectedItem).length === 0 &&
          Object.getPrototypeOf(selectedItem) === Object.prototype ? (
            <div style={{ minHeight: 200, overflowY: "scroll" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Combobox
                  onOptionSelect={(val, data) => {
                    console.log(val);
                    console.log(data);
                    setSelectedMenuItem(data.optionValue);
                  }}
                  placeholder="Object/Entity"
                >
                  {objectTypes.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Combobox>
                {selectedMenuItem != "insights" && (
                  <Input
                    contentBefore={<SearchFilled />}
                    placeholder="Search..."
                    style={{ marginRight: 20 }}
                    value={query}
                    onChange={(val, data) => setQuery(data.value)}
                  />
                )}
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>Name</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {listItems.length > 0 &&
                    listItems.map((item, i) => (
                      <TableRow
                        className="table"
                        style={{ backgroundColor: "#121212" }}
                        onClick={() => setSelectedItem(item)}
                      >
                        <TableCell>
                          {" "}
                          {selectedMenuItem == "Issue"
                            ? "ISSUE"
                            : selectedMenuItem == "Battery"
                            ? "BATTERY"
                            : selectedMenuItem == "Well"
                            ? "WELL"
                            : selectedMenuItem == "Site"
                            ? "SITE"
                            : "INSIGHT"}
                          -{item?.readableId}
                        </TableCell>
                        <TableCell>
                          {item?.subject ? item.subject : item.name}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {loading ? (
                <Spinner
                  style={{ margin: 20 }}
                  appearance="inverted"
                  label="Loading"
                />
              ) : (
                !loading &&
                listItems.length == 0 && (
                  <p style={{ margin: 20, textAlign: "center" }}>No data</p>
                )
              )}
            </div>
          ) : (
            <Item
              screenSize={matches}
              type="insight"
              item={selectedItem}
              msID={msID}
              message={message}
              fromUser={fromUser}
              teamsUrl={teamsUrl}
              setItem={setSelectedItem}
            />
          )}
        </div>
      )}
    </div>
  );
}
