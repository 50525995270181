import React from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  createDarkTheme,
  createLightTheme,
  FluentProvider,
  webDarkTheme,
} from "@fluentui/react-components";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";

import { app, pages, authentication, profile } from "@microsoft/teams-js";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import "./App.css";
import TabConfig from "./TabConfig";
import { CreateIssue } from "./botWebView/CreateIssue";
import { refreshToken } from "../authUtil";
import { useEffect } from "react";
import { Welcome } from "./tabViews/Welcome";
import { ShareTo } from "./botWebView/ShareTo";
import { initializeApp } from "firebase/app";
import { logEvent, getAnalytics } from "firebase/analytics";

const myBrand = {
  10: "#000000",
  20: "#011800",
  30: "#002700",
  40: "#00360c",
  50: "#004612",
  60: "#005618",
  70: "#00671f",
  80: "#3ff391",
  90: "#3ff391",
  100: "#3ff391",
  110: "#3ff391",
  120: "#3ff391",
  130: "#3ff391",
  140: "#aad5a9",
  150: "#c6e4c5",
  160: "#e2f2e2",
};

const lightTheme = createLightTheme(myBrand);
const darkTheme = createDarkTheme(myBrand);

const firebaseConfig = {
  apiKey: "AIzaSyAXn22do7g6wtjOjnP_iy8XSgjomV148dk",
  authDomain: "welltender-78eb5.firebaseapp.com",
  projectId: "welltender-78eb5",
  storageBucket: "welltender-78eb5.appspot.com",
  messagingSenderId: "82089394197",
  appId: "1:82089394197:web:6ec09a049b03a687894e1c",
  measurementId: "G-3LPEJ3BXDN",
};

export default function App() {
  const [msID, setmsID] = React.useState(null);
  const [isAuthed, setIsAuthed] = React.useState(true);
  app.initialize();

  console.log("Analytics!");
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  const logEV = logEvent(analytics, "teams_app_open");
  console.log(JSON.stringify(analytics));
  console.log("Analytics End!");
  console.log(logEV);

  useEffect(() => {
    getContext();
  }, []);

  const getContext = async () => {
    const info = await app.getContext();
    setmsID(info.user.id);
    const auth = await refreshToken({ user: info.user.id });
    console.log(auth);
    if (auth && auth?.statusCode == 401) {
      setIsAuthed(false);
    } else {
      setIsAuthed(true);
    }
  };
  return (
    <FluentProvider theme={darkTheme}>
      <Router>
        <Route exact path="/">
          <Redirect to="/tab" />
        </Route>
        <>
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/termsofuse" component={TermsOfUse} />
          <Route
            exact
            path="/tab"
            render={() => (
              <Welcome
                msID={msID}
                getContext={getContext}
                isAuthed={isAuthed}
                analytics={analytics}
              />
            )}
          />
          <Route
            exact
            path="/createIssue"
            render={() => (
              <CreateIssue
                msID={msID}
                getContext={getContext}
                isAuthed={isAuthed}
                analytics={analytics}
              />
            )}
          />

          <Route
            exact
            path="/shareTo"
            render={() => (
              <ShareTo
                msID={msID}
                getContext={getContext}
                isAuthed={isAuthed}
                analytics={analytics}
              />
            )}
          />
          <Route exact path="/config" component={TabConfig} />
        </>
      </Router>
    </FluentProvider>
  );
}
