import React from "react";
import "./CreateIssue.css";
import { useEffect } from "react";
import {
  Combobox,
  CompoundButton,
  Input,
  Option,
  Text,
  Textarea,
} from "@fluentui/react-components";
import { Add16Filled } from "@fluentui/react-icons";
import { refreshToken } from "../../authUtil";
import Login from "../tabViews/Login";

export function CreateIssue({ msID, getContext, isAuthed }) {
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const priorities = ["LOWEST", "LOW", "MEDIUM", "HIGH", "HIGHEST"];
  const types = ["Bust", "Task", "Case", "Inspection"];
  //GET URL PARAM FROM #/createIssue?message=
  const hash = window.location.hash;
  let params = hash.split("&");
  let data = [];
  params.forEach(function (d) {
    let index = d.indexOf("=");
    let keyLength = index > -1 ? index : d.length;
    let key = d.substr(0, keyLength);
    let value = index > -1 ? d.substr(index + 1) : "";
    data.push({ key: key, value: value });
  });
  console.log(data);
  const decoded = decodeURI(data[0].value);
  const message = decoded.replace(/<.*?>/g, "");
  const teamsUrl = data[1].value;
  const fromUser = decodeURI(data[2].value);
  //END GET URL PARAM FROM #/createIssue?message=
  const [subject, setSubject] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [priority, setPriority] = React.useState("");
  const [type, setType] = React.useState("");
  const [posted, setPosted] = React.useState(false);
  const [issueUrl, setIssueUrl] = React.useState("");

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    setDescription(message);
  }, []);

  const postCreateIssue = async () => {
    const user = await refreshToken({ user: msID });

    console.log(user);

    // Create issue Teams Tab

    // const isUserAdded =
    //   payload.view.state.values.user_select.user_select.selected_option ==
    //   null
    //     ? false
    //     : true;
    // const isAssetAdded =
    //   payload.view.state.values.asset.asset.selected_option == null
    //     ? false
    //     : true;

    const isUserAdded = false;
    const isAssetAdded = false;

    let issueData = {
      query:
        "\n    mutation CreateIssues($assetIds: [ID!], $issue: InputIssue, $cloneForWells: Boolean) {\n  createIssues(assetIds: $assetIds, issue: $issue, cloneForWells: $cloneForWells) {\n    ...MinimalIssue\n  }\n}\n    \n    fragment MinimalIssue on Issue {\n  __typename\n  createdAt\n  id\n  readableId\n  subject\n  updatedAt\n  type\n}\n",
      variables: {
        cloneForWells: false,
        // ...(isAssetAdded && {
        //   assetIds: [
        //     payload.view.state.values.asset.asset.selected_option.value, //ID of the asset
        //   ],
        // }),
        issue: {
          //   ...(isUserAdded && {
          //     assigneeId:
          //       payload.view.state.values.user_select.user_select
          //         .selected_option.value,
          //   }),
          access: { private: false, roles: [], users: [] },
          attachments: [],
          customFields: null,
          description:
            description + " From - " + fromUser + " via Teams: " + teamsUrl,
          dispatched: false,
          estimatedEndDate: null, //new Date(),
          estimatedStartDate: null, //new Date(),
          flag: false,
          labels: [],
          priority: priority,
          status: "BACKLOG",
          subject: subject,
          type: type,
        },
      },
      operationName: "CreateIssues",
    };

    // //if(payload.view.state.values.user_select.user_select.selected_option.value){assigneeId = payload.view.state.values.user_select.user_select.selected_option.value}

    // const md = JSON.parse(payload.view.private_metadata);
    // //channel: payload.channel.id,
    // //ts: payload.message_ts,
    // //user: payload.user.id,
    // console.log(md);

    const baseUrl =
      user.tenant == "dev"
        ? "https://api.dev.resnet.ai/graphql"
        : user.tenant == "staging"
        ? "https://api.staging.resnet.ai/graphql"
        : "https://api.resnet.ai/graphql";

    let url = `https://${user.tenant}.resnet.ai/issues/issue/`;

    await fetch(baseUrl, {
      method: "POST",
      body: JSON.stringify(issueData),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((res) => {
        //Hide modal
        console.log(res);
        setPosted(true);
        console.log(res.data.createIssues[0]);
        url += res.data.createIssues[0].id + "/feed";
        setIssueUrl(url);
        console.log(url);
      });

    return "success";
  };

  return (
    <div className="home" style={{ backgroundColor: "#121212" }}>
      {isAuthed == false ? (
        <div className="narrow">
          <Login msID={msID} getContext={getContext} />
        </div>
      ) : posted == false ? (
        <div className="deploy page">
          <div className="issue">
            <Input
              onChange={(val, data) => {
                console.log(val);
                console.log(data);
                setSubject(data.value);
              }}
              value={subject}
              placeholder="Issue Subject"
              style={{ flex: 1, marginTop: 10 }}
            />
            <Combobox
              style={{ marginTop: 10 }}
              onOptionSelect={(val, data) => {
                console.log(val);
                console.log(data);
                setPriority(data.optionValue);
              }}
              placeholder="Priority"
            >
              {priorities.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Combobox>
            <Combobox
              style={{ marginTop: 10 }}
              onOptionSelect={(val, data) => {
                console.log(val);
                console.log(data);
                setType(data.optionValue);
              }}
              placeholder="Issue Type"
            >
              {types.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Combobox>
            <Textarea
              onChange={(val, data) => {
                setDescription(data.optionValue);
              }}
              value={description}
              placeholder="Description"
              style={{ flex: 1, marginTop: 10 }}
            />
            <CompoundButton
              onClick={() => postCreateIssue()}
              style={{ flex: 1, marginTop: 10 }}
              icon={<Add16Filled />}
              appearance={"primary"}
              //secondaryContent={screenSize ? "Post comment" : null}
              size={"small"}
            >
              Create Issue
            </CompoundButton>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          The post has been created. Visit this link to open the new post:{" "}
          <a target={"_blank"} style={{ color: "#3ff391" }} href={issueUrl}>
            {issueUrl}
          </a>
        </div>
      )}
    </div>
  );
}
