export const refreshToken = async (id) => {
  let userData;
  console.log(id);
  await fetch("https://resnet-msteams-auth.glitch.me/user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(id),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      console.log(data);
      userData = data;
    });
  return userData;
};
