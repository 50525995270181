import React, { useEffect } from "react";
import { Button, Input, Label } from "@fluentui/react-components";
import { LockClosedFilled } from "@fluentui/react-icons";

export default function Login({ msID, getContext }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [tenant, setTenant] = React.useState("");
  const [wrongUsernamePasssword, setWrongUsernamePassword] =
    React.useState(false);

  const login = () => {
    const loginUrl =
      tenant == "dev"
        ? "https://auth.dev.resnet.ai/sign-in"
        : tenant == "staging"
        ? "https://auth.staging.resnet.ai/sign-in"
        : "https://auth.resnet.ai/sign-in";
    const signindata = {
      tenant: tenant,
      email: username,
      password: password,
    };
    fetch(loginUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signindata),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //Refresh Token in Middleware
        if (data.statusCode >= 400) {
          console.log("LOGIN FAILED");
          setWrongUsernamePassword(true);
        } else {
          const user = {
            id: msID,
            tenant: tenant,
            accessToken: data.token,
            refreshToken: data.refreshToken,
            expires: data.expired_in,
          };
          console.log(user);
          getContext();
          fetch("https://resnet-msteams-auth.glitch.me/addUser", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
          });
        }
      });
  };

  return (
    <div style={{ paddingTop: 50, maxWidth: 500, margin: "0 auto" }}>
      <div className="narrow">
        <Label size="large">Please Login to WellTender</Label>
        <div style={{ width: "100%", marginTop: 20 }}>
          <Label htmlFor={"tenant"}>Copmany ID</Label>
          <Input
            type="text"
            id="tenant"
            placeholder="Type you company id"
            style={{ width: "100%" }}
            onChange={(e) => setTenant(e.target.value)}
          />
        </div>
        <div style={{ width: "100%", marginTop: 10 }}>
          <Label htmlFor={"email"}>Email</Label>
          <Input
            type="email"
            placeholder="Type you e-mail"
            style={{ width: "100%" }}
            id={"email"}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div style={{ width: "100%", marginTop: 10 }}>
          <Label htmlFor={"password"}>Password</Label>
          <Input
            id="password"
            placeholder="Type you password"
            style={{ width: "100%" }}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {wrongUsernamePasssword ? (
          <p style={{ color: "red" }}>Wrong username or password</p>
        ) : null}
        <div style={{ paddingTop: 20 }}>
          <Button
            size="medium"
            icon={<LockClosedFilled />}
            onClick={() => login()}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}
